let height;

const sendPostMessage = () => {
    let canvas = document.getElementById('row-offcanvas');
    if (canvas && canvas.offsetHeight && height !== canvas.offsetHeight) {
        height = canvas.offsetHeight;
        window.parent.postMessage({
            frameHeight: height
        }, '*');
    }
}

const debounced = _.debounce(() => sendPostMessage());

/**
 * Used setInterval to always send the frame height to parent page.
 * If the iframe contents change, so the frame height is.
 */
setInterval(() => {
    debounced();
});
